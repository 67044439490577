<template>
  <div>
    {{ getProductCount() }} Prodotti, {{ getProducertCount() }} produttori
  </div>
</template>

<script>
import get from "lodash/get";

export default {
  name: "OrdersTableInfo",

  methods: {
    getProductCount() {
      return get(this.data, `tot_prods`);
    },

    getProducertCount() {
      return get(this.data, `tot_producers`);
    },
  },
};
</script>
